import { Components } from '@mui/material/styles';
import { pxToRem } from '../../utils';

const iconMaterialOptions: Components['MuiIcon'] = {
  defaultProps: {
    baseClassName: 'material-icons-round',
    fontSize: 'inherit',
  },

  styleOverrides: {
    fontSizeInherit: {
      fontSize: 'inherit !important',
    },

    fontSizeSmall: {
      fontSize: `${pxToRem(20)} !important`,
    },

    fontSizeLarge: {
      fontSize: `${pxToRem(36)} !important`,
    },
  },
};

export default iconMaterialOptions;
