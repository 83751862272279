import { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';
import Icon from '@mui/material/Icon';
import MdBox from 'src/components/common/Box';
import MdTypography from 'src/components/common/Typography';

type Props = {
  icon: ReactNode | string;
  title: string;
  route: string[];
  light?: boolean;
};

const Breadcrumbs: FC<Props> = ({ icon, title, route, light = false }) => {
  const routes = route.slice(0, -1);

  return (
    <MdBox mr={{ xs: 0, xl: 8 }}>
      <MuiBreadcrumbs
        sx={{
          '& .MuiBreadcrumbs-separator': {
            color: ({ palette: { white, grey } }: any) => (light ? white.main : grey[600]),
          },
        }}
      >
        <Link to="/">
          <MdTypography
            variant="body2"
            color={light ? 'white' : 'dark'}
            opacity={light ? 0.8 : 0.5}
            sx={{ lineHeight: 0 }}
          >
            <Icon>{icon}</Icon>
          </MdTypography>
        </Link>
        {routes.map((el) => (
          <Link to={`/${el}`} key={el}>
            <MdTypography
              variant="button"
              fontWeight="regular"
              textTransform="capitalize"
              color={light ? 'white' : 'dark'}
              opacity={light ? 0.8 : 0.5}
              sx={{ lineHeight: 0 }}
            >
              {el.replace('-', ' ')}
            </MdTypography>
          </Link>
        ))}
        <MdTypography
          variant="button"
          fontWeight="regular"
          textTransform="capitalize"
          color={light ? 'white' : 'dark'}
          sx={{ lineHeight: 0 }}
        >
          {title.replace('-', ' ')}
        </MdTypography>
      </MuiBreadcrumbs>
      <MdTypography fontWeight="bold" textTransform="capitalize" variant="h6" color={light ? 'white' : 'dark'} noWrap>
        {title.replace('-', ' ')}
      </MdTypography>
    </MdBox>
  );
};

export default Breadcrumbs;
