import Icon from '@mui/material/Icon';
import { FC, PropsWithChildren, useState } from 'react';
import { Outlet } from 'react-router-dom';
import brandDark from 'src/assets/images/logo-ct-dark.png';
import brandWhite from 'src/assets/images/logo-ct.png';
import { HORIZONTAL_LEFT_MARGIN_MINI_SIDENAV, HORIZONTAL_LEFT_MARGIN_OPEN_SIDENAV } from 'src/assets/theme/constants';
import { setMiniSidenav, setOpenConfigurator, useMaterialUIController } from 'src/assets/theme/context';
import { AppTheme } from 'src/assets/theme/types';
import MdBox from 'src/components/common/Box';
import Configurator from 'src/components/Configurator';
import SideNav from 'src/components/Sidenav';
import routes from 'src/routes';

const DashboardBaseLayout: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, sidenavColor, transparentSidenav, whiteSidenav, darkMode, openConfigurator } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);

  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  const configsButton = (
    <MdBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: 'pointer' }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MdBox>
  );

  return (
    <>
      <SideNav
        color={sidenavColor}
        brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
        brandName="Admin Template"
        routes={routes}
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
      />
      <Configurator />
      {configsButton}
      <MdBox
        // @ts-ignore
        sx={({ breakpoints, transitions, functions: { pxToRem } }: AppTheme) => ({
          p: 3,
          position: 'relative',
          [breakpoints.up('xl')]: {
            marginLeft: miniSidenav
              ? pxToRem(HORIZONTAL_LEFT_MARGIN_MINI_SIDENAV)
              : pxToRem(HORIZONTAL_LEFT_MARGIN_OPEN_SIDENAV),
            transition: transitions.create(['margin-left', 'margin-right'], {
              easing: transitions.easing.easeInOut,
              duration: transitions.duration.standard,
            }),
          },
        })}
      >
        {children}
        <Outlet />
      </MdBox>
    </>
  );
};

export default DashboardBaseLayout;
