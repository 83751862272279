import { Components } from '@mui/material/styles';
import borders from '../base/borders';
import colors from '../base/colors';
import { pxToRem } from '../../utils';

const { borderRadius } = borders;
const { light } = colors;

const linearProgressMaterialOptions: Components['MuiLinearProgress'] = {
  styleOverrides: {
    root: {
      height: pxToRem(6),
      borderRadius: borderRadius.md,
      overflow: 'visible',
      position: 'relative',
    },

    colorPrimary: {
      backgroundColor: light.main,
    },

    colorSecondary: {
      backgroundColor: light.main,
    },

    bar: {
      height: pxToRem(6),
      borderRadius: borderRadius.sm,
      position: 'absolute',
      transform: `translate(0, 0) !important`,
      transition: 'width 0.6s ease !important',
    },
  },
};

export default linearProgressMaterialOptions;
