import Icon from '@mui/material/Icon';

/* 
  ATTENTIONS: Current design only allowing for 3 level depth sidenav!
    collapseName: take out the key to determine as [active], based on the first partial pathname
    itemParentName: take out the key to determine as [active], based on the second partial pathname
    itemName: take out the key to determine as [active], based on the last partial pathname
    
  ** [key] here is just some part to identify if current section was active on side bar (no need to be unique)
  as long as it depend on pathname, route and key have relation.
*/
export type RouteDetail = {
  key: string;
  type?: 'collapse' | 'divider' | 'title';
  title?: string;
  name: string;
  href?: string;
  icon?: JSX.Element;
  authenticateRequired?: boolean;
  route?: string;
  suppressRoute?: boolean;
  component?: JSX.Element;
  collapse?: RouteDetail[];
  noCollapse?: boolean;
};

/*==========================================================================*/
export const loading = () => <div>Please wait!!</div>;

/*==========================================================================*/
const routes: RouteDetail[] = [
  {
    key: 'stocks',
    name: 'Stock Reports',
    type: 'collapse',
    route: 'stocks',
    suppressRoute: true,
    icon: <Icon fontSize="medium">candlestick_chart</Icon>,
    collapse: [
      {
        key: 'price-index-ratio',
        name: 'Price Index Ratio Report',
        route: 'price-index-ratio',
      },
      {
        key: 'self-defined-category-cashflow',
        name: 'Self Defined Category Cashflow Report',
        route: 'self-defined-category-cashflow',
      },
      {
        key: 'general-info',
        name: 'General Stock Info',
        route: 'general-info',
      },
    ],
  },
  {
    key: 'gold-price',
    name: 'Gold Price Chart',
    type: 'collapse',
    route: 'gold-price',
    suppressRoute: true,
    icon: <Icon fontSize="medium">candlestick_chart</Icon>,
    collapse: [
      {
        key: 'gold-price-chart',
        name: 'Gold Price History',
        route: 'chart',
      },
    ],
  },
];

export default routes;
