import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { AppTheme, BaseColors, validBorderRadius, validBoxShadows } from 'src/assets/theme/types';

export type OwnerStateProps = {
  variant: 'contained' | 'gradient';
  bgColor: BaseColors | string;
  color: string;
  opacity: number;
  borderRadius: string; // BorderRadius, but accept many others like 50%, 25%,...
  shadow: string;
  coloredShadow: BaseColors | 'none';
};

export default styled(Box)(({ theme, ownerState }: any) => {
  const { palette, functions, borders, boxShadows }: AppTheme = theme;
  const { variant, bgColor, color, opacity, borderRadius, shadow, coloredShadow }: OwnerStateProps = ownerState;

  const { gradients, grey, white } = palette;
  const { linearGradient } = functions;
  const { borderRadius: radius } = borders;
  const { colored } = boxShadows;

  const greyColors = {
    'grey-100': grey[100],
    'grey-200': grey[200],
    'grey-300': grey[300],
    'grey-400': grey[400],
    'grey-500': grey[500],
    'grey-600': grey[600],
    'grey-700': grey[700],
    'grey-800': grey[800],
    'grey-900': grey[900],
  };

  const validGradients = ['primary', 'secondary', 'info', 'success', 'warning', 'error', 'dark', 'light'];

  const validColors = [
    'transparent',
    'white',
    'black',
    'primary',
    'secondary',
    'info',
    'success',
    'warning',
    'error',
    'light',
    'dark',
    'text',
    'grey-100',
    'grey-200',
    'grey-300',
    'grey-400',
    'grey-500',
    'grey-600',
    'grey-700',
    'grey-800',
    'grey-900',
  ];

  // background value
  let backgroundValue = bgColor;

  if (variant === 'gradient') {
    backgroundValue = validGradients.find((el) => el === bgColor)
      ? linearGradient((gradients as any)[bgColor].main, (gradients as any)[bgColor].state)
      : white.main;
  } else if (validColors.find((el) => el === bgColor)) {
    backgroundValue = (palette as any)[bgColor] ? (palette as any)[bgColor].main : (greyColors as any)[bgColor];
  } else {
    backgroundValue = bgColor;
  }

  // color value
  let colorValue = color;

  if (validColors.find((el) => el === color)) {
    colorValue = (palette as any)[color] ? (palette as any)[color].main : (greyColors as any)[color];
  }

  // borderRadius value
  let borderRadiusValue = borderRadius as string;

  if (validBorderRadius.find((el) => el === borderRadius)) {
    borderRadiusValue = (radius as any)[borderRadius];
  }

  // boxShadow value
  let boxShadowValue = 'none';

  if (validBoxShadows.find((el) => el === shadow)) {
    boxShadowValue = (boxShadows as any)[shadow];
  } else if (coloredShadow) {
    boxShadowValue = (colored as any)[coloredShadow] ? (colored as any)[coloredShadow] : 'none';
  }

  return {
    opacity,
    background: backgroundValue,
    color: colorValue,
    borderRadius: borderRadiusValue,
    boxShadow: boxShadowValue,
  };
}) as any;
