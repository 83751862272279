import { Components } from '@mui/material/styles';

const linkMaterialOptions: Components['MuiLink'] = {
  defaultProps: {
    underline: 'none',
    color: 'inherit',
  },
};

export default linkMaterialOptions;
