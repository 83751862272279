import { createContext, useContext, useReducer } from 'react';

export type AppThemeContext = {
  miniSidenav: boolean;
  transparentSidenav: boolean;
  whiteSidenav: boolean;
  sidenavColor: 'info';
  transparentNavbar: boolean;
  fixedNavbar: boolean;
  openConfigurator: boolean;
  direction: 'ltr';
  layout: 'dashboard';
  darkMode: boolean;
};

// =========================Context module functions=========================
const setMiniSidenav = (dispatch: React.Dispatch<{ type: string; value: any }>, value: boolean) =>
  dispatch({ type: 'MINI_SIDENAV', value });
const setTransparentSidenav = (dispatch: React.Dispatch<{ type: string; value: any }>, value: boolean) =>
  dispatch({ type: 'TRANSPARENT_SIDENAV', value });
const setWhiteSidenav = (dispatch: React.Dispatch<{ type: string; value: any }>, value: boolean) =>
  dispatch({ type: 'WHITE_SIDENAV', value });
const setSidenavColor = (dispatch: React.Dispatch<{ type: string; value: any }>, value: string) =>
  dispatch({ type: 'SIDENAV_COLOR', value });
const setTransparentNavbar = (dispatch: React.Dispatch<{ type: string; value: any }>, value: boolean) =>
  dispatch({ type: 'TRANSPARENT_NAVBAR', value });
const setFixedNavbar = (dispatch: React.Dispatch<{ type: string; value: any }>, value: boolean) =>
  dispatch({ type: 'FIXED_NAVBAR', value });
const setOpenConfigurator = (dispatch: React.Dispatch<{ type: string; value: any }>, value: boolean) =>
  dispatch({ type: 'OPEN_CONFIGURATOR', value });
const setDirection = (dispatch: React.Dispatch<{ type: string; value: any }>, value: 'ltr' | 'rtl') =>
  dispatch({ type: 'DIRECTION', value });
const setLayout = (dispatch: React.Dispatch<{ type: string; value: any }>, value: string) =>
  dispatch({ type: 'LAYOUT', value });
const setDarkMode = (dispatch: React.Dispatch<{ type: string; value: any }>, value: boolean) =>
  dispatch({ type: 'DARKMODE', value });

function reducer(state: AppThemeContext, action: { type: string; value: any }) {
  switch (action.type) {
    case 'MINI_SIDENAV': {
      return { ...state, miniSidenav: action.value };
    }
    case 'TRANSPARENT_SIDENAV': {
      return { ...state, transparentSidenav: action.value };
    }
    case 'WHITE_SIDENAV': {
      return { ...state, whiteSidenav: action.value };
    }
    case 'SIDENAV_COLOR': {
      return { ...state, sidenavColor: action.value };
    }
    case 'TRANSPARENT_NAVBAR': {
      return { ...state, transparentNavbar: action.value };
    }
    case 'FIXED_NAVBAR': {
      return { ...state, fixedNavbar: action.value };
    }
    case 'OPEN_CONFIGURATOR': {
      return { ...state, openConfigurator: action.value };
    }
    case 'DIRECTION': {
      return { ...state, direction: action.value };
    }
    case 'LAYOUT': {
      return { ...state, layout: action.value };
    }
    case 'DARKMODE': {
      return { ...state, darkMode: action.value };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

// =========================Initialize Context=========================
const initialState: AppThemeContext = {
  miniSidenav: true,
  transparentSidenav: false,
  whiteSidenav: false,
  sidenavColor: 'info',
  transparentNavbar: true,
  fixedNavbar: false,
  openConfigurator: false,
  direction: 'ltr',
  layout: 'dashboard',
  darkMode: false,
};

const MaterialUIThemeContext = createContext(initialState);
MaterialUIThemeContext.displayName = 'MaterialUIThemeContext';

function MaterialUIControllerProvider({ children }: any) {
  const [controller, dispatch] = useReducer(reducer, initialState);

  return (
    <MaterialUIThemeContext.Provider value={[controller, dispatch] as any}>{children}</MaterialUIThemeContext.Provider>
  );
}

function useMaterialUIController(): [AppThemeContext, React.Dispatch<{ type: string; value: any }>] {
  const context = useContext(MaterialUIThemeContext);

  if (!context) {
    throw new Error('useMaterialUIController should be used inside the MaterialUIControllerProvider.');
  }

  return context as any;
}

export {
  MaterialUIControllerProvider,
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
  setSidenavColor,
  setTransparentNavbar,
  setFixedNavbar,
  setOpenConfigurator,
  setDirection,
  setLayout,
  setDarkMode,
};
