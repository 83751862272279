import { forwardRef, ReactNode } from 'react';
import MenuItem, { MenuItemProps } from '@mui/material/MenuItem';
import Link from '@mui/material/Link';
import MdBox from 'src/components/common/Box';
import MdTypography from 'src/components/common/Typography';
import menuItem from './styles';

type NotificationItemProps = {
  icon: ReactNode;
  title: string;
};

const NotificationItem = forwardRef<HTMLLIElement | null, NotificationItemProps & MenuItemProps>(
  ({ icon, title, ...rest }, ref) => (
    <MenuItem {...rest} sx={(theme) => menuItem(theme)} ref={ref}>
      <MdBox component={Link} py={0.5} display="flex" alignItems="center" lineHeight={1}>
        <MdTypography variant="body1" color="secondary" lineHeight={0.75}>
          {icon}
        </MdTypography>
        <MdTypography variant="button" fontWeight="regular" sx={{ ml: 1 }}>
          {title}
        </MdTypography>
      </MdBox>
    </MenuItem>
  ),
);

export default NotificationItem;
