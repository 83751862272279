/* 
As we passing a few extra property to created theme for material UI.
There will be many violated with typescript constraints.
So cast things as any to bypass them.
If we need some intellisense, simply just remove any to see the actual type!
*/

import { Theme } from '@mui/material/styles';
import { AppThemeColor as LightThemePalette } from './light/base/colors';
import { AppTypographyOptions } from './dark/base/typography';
import { extraThemeOptions as extraDarkThemeOptions } from './dark/index';

export type { AppThemeColor as AppThemePalette } from './light/base/colors';

// dont use this object for light theme, cause we using dark one for more properties
export type AppTheme = typeof extraDarkThemeOptions &
  Omit<Theme, 'palette' | 'typography'> & {
    palette: LightThemePalette;
    typography: AppTypographyOptions;
  };

export type BorderRadius = keyof typeof extraDarkThemeOptions.borders.borderRadius | 'none';
export type BaseColors = 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error' | 'light' | 'dark';
export type SocialButtonColors =
  | 'facebook'
  | 'twitter'
  | 'instagram'
  | 'linkedin'
  | 'pinterest'
  | 'youtube'
  | 'github'
  | 'vimeo'
  | 'slack'
  | 'dribbble'
  | 'reddit'
  | 'tumblr';
export type Size = 'xs' | 'sm' | 'md' | 'lg';
export type TextTransform = 'none' | 'capitalize' | 'uppercase' | 'lowercase';
export type FontWeight = 'light' | 'regular' | 'medium' | 'bold';

// Flat object validation, cause style might have nested property, which needed to drill down
export const validBorderRadius = ['xs', 'sm', 'md', 'lg', 'xl', 'xxl', 'section'];
export const validBoxShadows = ['xs', 'sm', 'md', 'lg', 'xl', 'xxl', 'inset'];

// SxProperty should return: SxProps<Theme> | undefined
