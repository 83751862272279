import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { AppTheme, TextTransform, FontWeight, BaseColors } from 'src/assets/theme/types';

export type OwnerStateProps = {
  color: BaseColors | 'inherit' | 'text' | 'white';
  textTransform: TextTransform;
  verticalAlign: 'unset' | 'baseline' | 'sub' | 'super' | 'text-top' | 'text-bottom' | 'middle' | 'top' | 'bottom';
  fontWeight?: FontWeight;
  opacity: number;
  textGradient: boolean;
  darkMode: boolean;
};

// @ts-ignore
export default styled(Typography)(({ theme, ownerState }: any) => {
  const { palette, typography, functions }: AppTheme = theme;
  const { color, textTransform, verticalAlign, fontWeight, opacity, textGradient, darkMode }: OwnerStateProps =
    ownerState;

  const { gradients, transparent, white } = palette;
  const { fontWeightLight, fontWeightRegular, fontWeightMedium, fontWeightBold } = typography;
  const { linearGradient } = functions;

  // fontWeight styles
  const fontWeights = {
    light: fontWeightLight,
    regular: fontWeightRegular,
    medium: fontWeightMedium,
    bold: fontWeightBold,
  };

  // styles for the typography with textGradient={true}
  const gradientStyles = () => ({
    backgroundImage:
      color !== 'inherit' && color !== 'text' && color !== 'white' && gradients[color]
        ? linearGradient(gradients[color].main, gradients[color].state)
        : linearGradient(gradients.dark.main, gradients.dark.state),
    display: 'inline-block',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: transparent.main,
    position: 'relative',
    zIndex: 1,
  });

  // color value
  let colorValue = color === 'inherit' || !palette[color] ? 'inherit' : palette[color].main;

  if (darkMode && (color === 'inherit' || !palette[color])) {
    colorValue = 'inherit';
  } else if (darkMode && color === 'dark') colorValue = white.main;

  return {
    opacity,
    textTransform,
    verticalAlign,
    textDecoration: 'none',
    color: colorValue,
    fontWeight: !!fontWeight && fontWeights[fontWeight],
    ...(textGradient && gradientStyles()),
  };
}) as any;
