import { Components } from '@mui/material/styles';

const appBarMaterialOptions: Components['MuiAppBar'] = {
  defaultProps: {
    color: 'transparent',
  },

  styleOverrides: {
    root: {
      boxShadow: 'none',
    },
  },
};

export default appBarMaterialOptions;
