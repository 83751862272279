/* eslint-disable max-len */
import { useState, useEffect } from 'react';
import Divider from '@mui/material/Divider';
import Switch from '@mui/material/Switch';
import IconButton from '@mui/material/IconButton';
import Icon from '@mui/material/Icon';
import MdBox from 'src/components/common/Box';
import MdTypography from 'src/components/common/Typography';
import MdButton from 'src/components/common/Button';
import ConfiguratorRoot from './ConfiguratorRoot';
import {
  useMaterialUIController,
  setOpenConfigurator,
  setTransparentSidenav,
  setWhiteSidenav,
  setMiniSidenav,
  setFixedNavbar,
  setSidenavColor,
  setDarkMode,
} from 'src/assets/theme/context';

export default function Configurator() {
  const [controller, dispatch] = useMaterialUIController();
  const { openConfigurator, miniSidenav, fixedNavbar, sidenavColor, transparentSidenav, whiteSidenav, darkMode } =
    controller;
  const [disabled, setDisabled] = useState(false);
  const sidenavColors = ['primary', 'dark', 'info', 'success', 'warning', 'error'];

  useEffect(() => {
    function handleDisabled() {
      return window.innerWidth > 1200 ? setDisabled(false) : setDisabled(true);
    }

    window.addEventListener('resize', handleDisabled);
    handleDisabled();
    return () => window.removeEventListener('resize', handleDisabled);
  }, []);

  const handleCloseConfigurator = () => setOpenConfigurator(dispatch, false);
  const handleTransparentSidenav = () => {
    setTransparentSidenav(dispatch, true);
    setWhiteSidenav(dispatch, false);
  };
  const handleWhiteSidenav = () => {
    setWhiteSidenav(dispatch, true);
    setTransparentSidenav(dispatch, false);
  };
  const handleDarkSidenav = () => {
    setWhiteSidenav(dispatch, false);
    setTransparentSidenav(dispatch, false);
  };
  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleFixedNavbar = () => setFixedNavbar(dispatch, !fixedNavbar);
  const handleDarkMode = () => setDarkMode(dispatch, !darkMode);

  // sidenav type buttons styles
  const sidenavTypeButtonsStyles = ({
    functions: { pxToRem },
    palette: { white, dark, background },
    borders: { borderWidth },
  }: any) => ({
    height: pxToRem(39),
    background: darkMode ? background.sidenav : white.main,
    color: darkMode ? white.main : dark.main,
    border: `${borderWidth[1]} solid ${darkMode ? white.main : dark.main}`,

    '&:hover, &:focus, &:focus:not(:hover)': {
      background: darkMode ? background.sidenav : white.main,
      color: darkMode ? white.main : dark.main,
      border: `${borderWidth[1]} solid ${darkMode ? white.main : dark.main}`,
    },
  });

  // sidenav type active button styles
  const sidenavTypeActiveButtonStyles = ({
    functions: { pxToRem, linearGradient },
    palette: { white, gradients, background },
  }: any) => ({
    height: pxToRem(39),
    background: darkMode ? white.main : linearGradient(gradients.dark.main, gradients.dark.state),
    color: darkMode ? background.sidenav : white.main,

    '&:hover, &:focus, &:focus:not(:hover)': {
      background: darkMode ? white.main : linearGradient(gradients.dark.main, gradients.dark.state),
      color: darkMode ? background.sidenav : white.main,
    },
  });

  return (
    <ConfiguratorRoot variant="permanent" ownerState={{ openConfigurator }}>
      <MdBox display="flex" justifyContent="space-between" alignItems="baseline" pt={4} pb={0.5} px={3}>
        <MdBox>
          <MdTypography variant="h5">Portal Configurator</MdTypography>
          <MdTypography variant="body2" color="text">
            See our dashboard options.
          </MdTypography>
        </MdBox>

        <Icon
          sx={({ typography: { size }, palette: { dark, white } }: any) => ({
            fontSize: `${size.lg} !important`,
            color: darkMode ? white.main : dark.main,
            stroke: 'currentColor',
            strokeWidth: '2px',
            cursor: 'pointer',
            transform: 'translateY(5px)',
          })}
          onClick={handleCloseConfigurator}
        >
          close
        </Icon>
      </MdBox>

      <Divider />

      <MdBox pt={0.5} pb={3} px={3}>
        <MdBox>
          <MdTypography variant="h6">Sidenav Colors</MdTypography>

          <MdBox mb={0.5}>
            {sidenavColors.map((color) => (
              <IconButton
                key={color}
                sx={({ borders: { borderWidth }, palette: { white, dark, background }, transitions }: any) => ({
                  width: '24px',
                  height: '24px',
                  padding: 0,
                  border: `${borderWidth[1]} solid ${darkMode ? background.sidenav : white.main}`,
                  borderColor: () => {
                    let borderColorValue = sidenavColor === color && dark.main;

                    if (darkMode && sidenavColor === color) {
                      borderColorValue = white.main;
                    }

                    return borderColorValue;
                  },
                  transition: transitions.create('border-color', {
                    easing: transitions.easing.sharp,
                    duration: transitions.duration.shorter,
                  }),
                  backgroundImage: ({ functions: { linearGradient }, palette: { gradients } }: any) =>
                    linearGradient(gradients[color].main, gradients[color].state),

                  '&:not(:last-child)': {
                    mr: 1,
                  },

                  '&:hover, &:focus, &:active': {
                    borderColor: darkMode ? white.main : dark.main,
                  },
                })}
                onClick={() => setSidenavColor(dispatch, color)}
              />
            ))}
          </MdBox>
        </MdBox>

        <MdBox mt={3} lineHeight={1}>
          <MdTypography variant="h6">Sidenav Type</MdTypography>
          <MdTypography variant="button" color="text">
            Choose between different sidenav types.
          </MdTypography>

          <MdBox
            sx={{
              display: 'flex',
              mt: 2,
              mr: 1,
            }}
          >
            <MdButton
              color="dark"
              variant="gradient"
              onClick={handleDarkSidenav}
              disabled={disabled}
              fullWidth
              sx={!transparentSidenav && !whiteSidenav ? sidenavTypeActiveButtonStyles : sidenavTypeButtonsStyles}
            >
              Dark
            </MdButton>
            <MdBox sx={{ mx: 1, width: '8rem', minWidth: '8rem' }}>
              <MdButton
                color="dark"
                variant="gradient"
                onClick={handleTransparentSidenav}
                disabled={disabled}
                fullWidth
                sx={transparentSidenav && !whiteSidenav ? sidenavTypeActiveButtonStyles : sidenavTypeButtonsStyles}
              >
                Transparent
              </MdButton>
            </MdBox>
            <MdButton
              color="dark"
              variant="gradient"
              onClick={handleWhiteSidenav}
              disabled={disabled}
              fullWidth
              sx={whiteSidenav && !transparentSidenav ? sidenavTypeActiveButtonStyles : sidenavTypeButtonsStyles}
            >
              White
            </MdButton>
          </MdBox>
        </MdBox>
        <MdBox display="flex" justifyContent="space-between" alignItems="center" mt={3} lineHeight={1}>
          <MdTypography variant="h6">Navbar Fixed</MdTypography>

          <Switch checked={fixedNavbar} onChange={handleFixedNavbar} />
        </MdBox>
        <Divider />
        <MdBox display="flex" justifyContent="space-between" alignItems="center" lineHeight={1}>
          <MdTypography variant="h6">Sidenav Mini</MdTypography>

          <Switch checked={miniSidenav} onChange={handleMiniSidenav} />
        </MdBox>
        <Divider />
        <MdBox display="flex" justifyContent="space-between" alignItems="center" lineHeight={1}>
          <MdTypography variant="h6">Light / Dark</MdTypography>

          <Switch checked={darkMode} onChange={handleDarkMode} />
        </MdBox>
        <Divider />
      </MdBox>
    </ConfiguratorRoot>
  );
}
