/* eslint-disable react-hooks/exhaustive-deps */
import Divider from '@mui/material/Divider';
import Icon from '@mui/material/Icon';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import { FC, useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import {
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
  useMaterialUIController,
} from 'src/assets/theme/context';
import MdBox from 'src/components/common/Box';
import MdTypography from 'src/components/common/Typography';
import { RouteDetail } from 'src/routes';

import SidenavCollapse from './SidenavCollapse';
import SidenavItem from './SidenavItem';
import SidenavList from './SidenavList';
import SidenavRoot from './SidenavRoot';
import sidenavLogoLabel from './styles/sidenav';

/* 
  ATTENTIONS: Current design only allowing for 3 level depth sidenav!
  collapseName: take out the key to determine as [active], based on the first partial pathname
  itemParentName: take out the key to determine as [active], based on the second partial pathname
  itemName: take out the key to determine as [active], based on the last partial pathname
*/

export type SideNavProps = {
  color: 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error' | 'dark';
  brand: string;
  brandName: string;
  routes: RouteDetail[];
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
};

const SideNav: FC<SideNavProps> = ({ color = 'info', brand = '', brandName, routes, ...rest }) => {
  const [openCollapse, setOpenCollapse] = useState<string | boolean>(false);
  const [openNestedCollapse, setOpenNestedCollapse] = useState<string | boolean>(false);
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode } = controller;
  const location = useLocation();
  const { pathname } = location;
  const collapseName = pathname.split('/').slice(1)[0];
  const items = pathname.split('/').slice(1);
  const itemParentName = items[1];
  const itemName = items[items.length - 1];

  let textColor = 'white';

  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = 'dark';
  } else if (whiteSidenav && darkMode) {
    textColor = 'inherit';
  }

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  useEffect(() => {
    setOpenCollapse(collapseName);
    setOpenNestedCollapse(itemParentName);
  }, []);

  useEffect(() => {
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
      setTransparentSidenav(dispatch, window.innerWidth < 1200 ? false : transparentSidenav);
      setWhiteSidenav(dispatch, window.innerWidth < 1200 ? false : whiteSidenav);
    }

    window.addEventListener('resize', handleMiniSidenav);
    handleMiniSidenav();
    return () => window.removeEventListener('resize', handleMiniSidenav);
  }, [dispatch, location]);

  // Render all the nested collapse items from the routes.js
  const renderNestedCollapse = (collapse: RouteDetail[], parentRoute: string | undefined) => {
    const template = collapse.map(({ name, route, suppressRoute, key, href }) =>
      href ? (
        <Link key={key} href={href} target="_blank" rel="noreferrer" sx={{ textDecoration: 'none' }}>
          <SidenavItem name={name} />
        </Link>
      ) : (
        <NavLink
          to={suppressRoute ? '' : (`${parentRoute}/${route}` as string)}
          key={key}
          style={{ textDecoration: 'none' }}
        >
          <SidenavItem name={name} active={route === pathname} />
        </NavLink>
      ),
    );

    return template;
  };
  // Render the all the collpases from the routes.js
  const renderCollapse = (collapses: RouteDetail[], parentRoute: string | undefined) =>
    collapses.map(({ name, collapse, route, suppressRoute, href, key }) => {
      let returnValue;
      const processedRoute = suppressRoute ? `/${route}` : `${parentRoute ? `/${parentRoute}/` : '/'}${route}`;

      if (Array.isArray(collapse)) {
        returnValue = (
          <SidenavItem
            key={key}
            color={color}
            name={name}
            active={key === itemParentName ? 'isParent' : false}
            open={openNestedCollapse === key}
            onClick={({ currentTarget }) =>
              openNestedCollapse === key && currentTarget.classList.contains('MuiListItem-root')
                ? setOpenNestedCollapse(false)
                : setOpenNestedCollapse(key)
            }
          >
            {renderNestedCollapse(collapse, processedRoute)}
          </SidenavItem>
        );
      } else {
        returnValue = href ? (
          <Link href={href} key={key} target="_blank" rel="noreferrer" sx={{ textDecoration: 'none' }}>
            <SidenavItem color={color} name={name} active={key === itemName} />
          </Link>
        ) : (
          <NavLink to={suppressRoute ? '' : processedRoute} key={key} style={{ textDecoration: 'none' }}>
            <SidenavItem color={color} name={name} active={key === itemName} />
          </NavLink>
        );
      }

      return <SidenavList key={key}>{returnValue}</SidenavList>;
    });

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = routes.map(({ type, name, route, icon, title, collapse, noCollapse, key, href }) => {
    let returnValue;

    if (type === 'collapse') {
      returnValue = href ? (
        <Link href={href} key={key} target="_blank" rel="noreferrer" sx={{ textDecoration: 'none' }}>
          <SidenavCollapse name={name} icon={icon} active={key === collapseName} noCollapse={noCollapse} />
        </Link>
      ) : (
        <SidenavCollapse
          key={key}
          name={name}
          icon={icon}
          active={key === collapseName}
          open={openCollapse === key}
          onClick={() => (openCollapse === key ? setOpenCollapse(false) : setOpenCollapse(key))}
        >
          {Array.isArray(collapse) && renderCollapse(collapse, route)}
        </SidenavCollapse>
      );
    } else if (type === 'title') {
      returnValue = (
        <MdTypography
          key={key}
          color={textColor as any}
          display="block"
          variant="caption"
          fontWeight="bold"
          textTransform="uppercase"
          pl={3}
          mt={2}
          mb={1}
          ml={1}
        >
          {title}
        </MdTypography>
      );
    } else if (type === 'divider') {
      returnValue = (
        <Divider
          key={key}
          light={
            (!darkMode && !whiteSidenav && !transparentSidenav) || (darkMode && !transparentSidenav && whiteSidenav)
          }
        />
      );
    }

    return returnValue;
  });

  return (
    <SidenavRoot {...rest} variant="permanent" ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}>
      <MdBox pt={3} pb={1} px={4} textAlign="center">
        <MdBox
          display={{ xs: 'block', xl: 'none' }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: 'pointer' }}
        >
          <MdTypography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: 'bold' }}>close</Icon>
          </MdTypography>
        </MdBox>
        {/* @ts-ignore */}
        <MdBox component={NavLink} to="/" display="flex" alignItems="center">
          {/* @ts-ignore */}
          {brand && <MdBox component="img" src={brand} alt="Brand" width="2rem" />}
          <MdBox width={!brandName && ('100%' as any)} sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}>
            <MdTypography variant="button" fontWeight="medium" color={textColor as any}>
              {brandName}
            </MdTypography>
          </MdBox>
        </MdBox>
      </MdBox>
      <Divider
        light={(!darkMode && !whiteSidenav && !transparentSidenav) || (darkMode && !transparentSidenav && whiteSidenav)}
      />
      <List>{renderRoutes}</List>
    </SidenavRoot>
  );
};

export default SideNav;
