import { forwardRef } from 'react';
import BoxRoot, { OwnerStateProps } from './MdBoxRoot';
import { BoxProps } from '@mui/material/Box';

export type MdBoxProps = Partial<OwnerStateProps> &
  BoxProps & { name?: string; type?: 'file' | undefined; multiple?: boolean };

const MdBox = forwardRef(
  (
    {
      variant = 'contained',
      bgColor = 'transparent',
      color = 'dark',
      opacity = 1,
      borderRadius = 'none',
      shadow = 'none',
      coloredShadow = 'none',
      children,
      ...rest
    }: MdBoxProps,
    ref,
  ) => (
    <BoxRoot {...rest} ref={ref} ownerState={{ variant, bgColor, color, opacity, borderRadius, shadow, coloredShadow }}>
      {children}
    </BoxRoot>
  ),
);

export default MdBox;
