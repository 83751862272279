import typography from '../../base/typography';
import borders from '../../base/borders';
import colors from '../../base/colors';
import { pxToRem, rgba } from '../../../utils';

const { size } = typography;
const { white } = colors;
const { borderWidth, borderColor } = borders;

export default {
  styleOverrides: {
    root: {
      padding: pxToRem(16),
      fontSize: size.md,
      color: rgba(white.main, 0.8),
    },

    dividers: {
      borderTop: `${borderWidth[1]} solid ${rgba(borderColor as string, 0.6)}`,
      borderBottom: `${borderWidth[1]} solid ${rgba(borderColor as string, 0.6)}`,
    },
  },
};
