import { Components } from '@mui/material/styles';
import root from './root';
import contained from './contained';
import outlined from './outlined';
import text from './text';

export const buttonMaterialOptions: Components['MuiButton'] = {
  defaultProps: {
    disableRipple: false,
  },
  styleOverrides: {
    contained: { ...contained.base },
    containedSizeSmall: { ...contained.small },
    containedSizeLarge: { ...contained.large },
    containedPrimary: { ...contained.primary },
    containedSecondary: { ...contained.secondary },
    outlined: { ...outlined.base },
    outlinedSizeSmall: { ...outlined.small },
    outlinedSizeLarge: { ...outlined.large },
    outlinedPrimary: { ...outlined.primary },
    outlinedSecondary: { ...outlined.secondary },
    text: { ...text.base },
    textSizeSmall: { ...text.small },
    textSizeLarge: { ...text.large },
    textPrimary: { ...text.primary },
    textSecondary: { ...text.secondary },
  },
};

export default {
  defaultProps: buttonMaterialOptions?.defaultProps,
  styleOverrides: { root: { ...root }, ...buttonMaterialOptions?.styleOverrides },
};
