import { forwardRef } from 'react';
import MdBadgeRoot, { OwnerStateProps } from './MdBadgeRoot';
import { BadgeProps } from '@mui/material/Badge';

export type MdBadgeProps = Partial<OwnerStateProps>;

const MdBadge = forwardRef(
  (
    {
      color = 'info',
      variant = 'gradient',
      size = 'sm',
      circular = false,
      indicator = false,
      border = false,
      children = undefined,
      container = false,
      ...rest
    }: MdBadgeProps & Omit<BadgeProps, 'variant'>,
    ref,
  ) => (
    <MdBadgeRoot
      {...rest}
      ref={ref}
      color="default"
      ownerState={{ color, variant, size, circular, indicator, border, container, children }}
    >
      {children}
    </MdBadgeRoot>
  ),
);

export default MdBadge;
