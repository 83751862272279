/* eslint-disable max-len */
import Drawer from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';
import { AppTheme } from 'src/assets/theme/types';
import { SIDENAV_WIDTH_OPEN, SIDENAV_WIDTH_MINI, SIDENAV_TRANSLATE_X_HIDDING } from 'src/assets/theme/constants';

export type OwnerStateProps = {
  transparentSidenav: boolean;
  whiteSidenav: boolean;
  miniSidenav: boolean;
  darkMode: boolean;
};

export default styled(Drawer)(({ theme, ownerState }: any) => {
  const { palette, boxShadows, transitions, breakpoints, functions }: AppTheme = theme;
  const { transparentSidenav, whiteSidenav, miniSidenav, darkMode }: OwnerStateProps = ownerState;

  const { transparent, gradients, white, background } = palette;
  const { xxl } = boxShadows;
  const { pxToRem, linearGradient } = functions;

  let backgroundValue = darkMode ? background.sidenav : linearGradient(gradients.dark.main, gradients.dark.state);

  if (transparentSidenav) {
    backgroundValue = transparent.main;
  } else if (whiteSidenav) {
    backgroundValue = white.main;
  }

  // styles for the sidenav when miniSidenav={false}
  const drawerOpenStyles = () => ({
    background: backgroundValue,
    transform: 'translateX(0)',
    transition: transitions.create('transform', {
      easing: transitions.easing.sharp,
      duration: transitions.duration.shorter,
    }),

    [breakpoints.up('xl')]: {
      boxShadow: transparentSidenav ? 'none' : xxl,
      marginBottom: transparentSidenav ? 0 : 'inherit',
      left: '0',
      width: SIDENAV_WIDTH_OPEN,
      transform: 'translateX(0)',
      transition: transitions.create(['width', 'background-color'], {
        easing: transitions.easing.sharp,
        duration: transitions.duration.enteringScreen,
      }),
    },
  });

  // styles for the sidenav when miniSidenav={true}
  const drawerCloseStyles = () => ({
    background: backgroundValue,
    transform: `translateX(${pxToRem(SIDENAV_TRANSLATE_X_HIDDING)})`, // move sidenav left over screen (so feels like dissapear)
    transition: transitions.create('transform', {
      easing: transitions.easing.sharp,
      duration: transitions.duration.shorter,
    }),

    [breakpoints.up('xl')]: {
      boxShadow: transparentSidenav ? 'none' : xxl,
      marginBottom: transparentSidenav ? 0 : 'inherit',
      left: '0',
      width: pxToRem(SIDENAV_WIDTH_MINI),
      overflowX: 'hidden',
      transform: 'translateX(0)',
      transition: transitions.create(['width', 'background-color'], {
        easing: transitions.easing.sharp,
        duration: transitions.duration.shorter,
      }),
    },
  });

  return {
    '& .MuiDrawer-paper': {
      boxShadow: xxl,
      border: 'none',

      ...(miniSidenav ? drawerCloseStyles() : drawerOpenStyles()),
    },
  };
}) as any;
