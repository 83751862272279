import { ReactNode, FC } from 'react';
import Collapse from '@mui/material/Collapse';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Icon from '@mui/material/Icon';
import MdBox, { MdBoxProps } from 'src/components/common/Box';
import { collapseItem, collapseIconBox, collapseIcon, collapseText, collapseArrow } from './styles/sidenavCollapse';
import { useMaterialUIController } from 'src/assets/theme/context';

type SidenavCollapseProps = {
  active: boolean;
  open: boolean;
  noCollapse: boolean;
  icon: ReactNode | string;
  onClick?: () => void;
};

const SidenavCollapse: FC<Partial<SidenavCollapseProps> & { name: string } & MdBoxProps> = ({
  icon,
  name,
  children,
  active = false,
  noCollapse = false,
  open = false,
  ...rest
}) => {
  const [controller] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode } = controller;

  return (
    <>
      <ListItem component="li">
        <MdBox
          {...rest}
          sx={(theme) => collapseItem(theme, { active, transparentSidenav, whiteSidenav, darkMode }) as any}
        >
          <ListItemIcon sx={(theme) => collapseIconBox(theme, { transparentSidenav, whiteSidenav, darkMode })}>
            {typeof icon === 'string' ? <Icon sx={(theme) => collapseIcon(theme, { active })}>{icon}</Icon> : icon}
          </ListItemIcon>

          <ListItemText
            primary={name}
            sx={(theme) =>
              collapseText(theme, {
                miniSidenav,
                transparentSidenav,
                active,
              })
            }
          />

          <Icon
            sx={(theme) =>
              collapseArrow(theme, {
                noCollapse,
                transparentSidenav,
                whiteSidenav,
                miniSidenav,
                open,
                active,
                darkMode,
              })
            }
          >
            expand_less
          </Icon>
        </MdBox>
      </ListItem>
      {children && (
        <Collapse in={open} unmountOnExit>
          {children}
        </Collapse>
      )}
    </>
  );
};

export default SidenavCollapse;
