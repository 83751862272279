import { FC, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import ToolBar from '@mui/material/Toolbar';
import Menu from '@mui/material/Menu';
import IconButton from '@mui/material/IconButton';
import Icon from '@mui/material/Icon';
import MdBox from 'src/components/common/Box';
import MdInput from 'src/components/common/Input';
import MdBadge from 'src/components/common/Badge';
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
} from 'src/assets/theme/context';
import NotificationItem from 'src/components/examples/NotificationItem';
import Breadcrumbs from 'src/components/Breadcrumbs';
import { navbar, navbarContainer, navbarRow, navbarDesktopMenu, navbarMobileMenu, navbarIconButton } from './styles';
import { AppTheme } from 'src/assets/theme/types';

type NavbarProps = {
  absolute?: boolean;
  isMini?: boolean;
};

const Navbar: FC<NavbarProps> = ({ absolute = false, isMini = false }) => {
  const [navbarType, setNavbarType] = useState<'sticky' | 'static'>('sticky');
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator, darkMode } = controller;
  const [openMenu, setOpenMenu] = useState<any>();
  const route = useLocation().pathname.split('/').slice(1);

  const handleMiniSideNav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (e: any) => setOpenMenu(e.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem icon={<Icon>email</Icon>} title="Check new messages" />
      <NotificationItem icon={<Icon>podcasts</Icon>} title="Manage Podcast sessions" />
      <NotificationItem icon={<Icon>shopping_cart</Icon>} title="Payment successfully completed" />
    </Menu>
  );

  const iconStyle = ({ palette: { dark, text }, functions: { rgba } }: AppTheme) => ({
    color: () => (darkMode ? (transparentNavbar ? rgba(text.main, 0.6) : text.main) : dark.main),
  });

  useEffect(() => {
    if (fixedNavbar) {
      setNavbarType('sticky');
    } else {
      setNavbarType('static');
    }

    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    window.addEventListener('scroll', handleTransparentNavbar);
    handleTransparentNavbar();

    return () => window.removeEventListener('scroll', handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  return (
    <AppBar
      position={navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, darkMode })}
    >
      <ToolBar sx={navbarContainer as any}>
        <MdBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
          <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} />
          <IconButton sx={navbarDesktopMenu} onClick={handleMiniSideNav} size="small" disableRipple>
            <Icon fontSize="medium" sx={(theme) => iconStyle(theme as any)}>
              {miniSidenav ? 'menu' : 'menu_open'}
            </Icon>
          </IconButton>
        </MdBox>
        {!isMini && (
          <MdBox sx={(theme) => navbarRow(theme, { isMini })}>
            <MdBox pr={1}>
              <MdInput label="Search here" />
            </MdBox>
            <MdBox color="inherit">
              <IconButton size="small" disableRipple color="inherit" sx={navbarMobileMenu} onClick={handleMiniSideNav}>
                <Icon fontSize="medium" sx={(theme) => iconStyle(theme as any)}>
                  {miniSidenav ? 'menu' : 'menu_open'}
                </Icon>
              </IconButton>
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleConfiguratorOpen}
              >
                <Icon fontSize="medium" sx={(theme) => iconStyle(theme as any)}>
                  settings
                </Icon>
              </IconButton>
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={(theme) => navbarIconButton(theme as any)}
                aria-controls="notification-menu"
                aria-haspopup="true"
                onClick={handleOpenMenu}
              >
                <MdBadge badgeContent={9} color="error" size="xs" circular>
                  <Icon sx={(theme) => iconStyle(theme as any)}>notifications</Icon>
                </MdBadge>
              </IconButton>
              {renderMenu()}
            </MdBox>
          </MdBox>
        )}
      </ToolBar>
    </AppBar>
  );
};

export default Navbar;
