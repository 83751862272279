import { forwardRef } from 'react';
import MdTypographyRoot, { OwnerStateProps } from './MdTypographyRoot';
import { useMaterialUIController } from 'src/assets/theme/context';
import { TypographyProps } from '@mui/material/Typography';

export type MdTypographyProps = Partial<OwnerStateProps>;

const MdTypography = forwardRef(
  (
    {
      color = 'dark',
      fontWeight = undefined,
      textTransform = 'none',
      verticalAlign = 'unset',
      textGradient = false,
      opacity = 1,
      children,
      ...rest
    }: MdTypographyProps & TypographyProps,
    ref,
  ) => {
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;

    return (
      <MdTypographyRoot
        {...rest}
        ref={ref}
        ownerState={{
          color,
          textTransform,
          verticalAlign,
          fontWeight,
          opacity,
          textGradient,
          darkMode,
        }}
      >
        {children}
      </MdTypographyRoot>
    );
  },
);

export default MdTypography;
