import { Components } from '@mui/material/styles';
import boxShadows from '../../base/boxShadows';
import borders from '../../base/borders';
import colors from '../../base/colors';
import { pxToRem } from '../../../utils';

const { background } = colors;
const { borderRadius } = borders;
const { md } = boxShadows;

export const tabsMaterialOptions: Components['MuiTabs'] = {
  styleOverrides: {
    root: {
      position: 'relative',
      backgroundColor: background.card,
      borderRadius: borderRadius.xl,
      minHeight: 'unset',
      padding: pxToRem(4),
    },

    flexContainer: {
      height: '100%',
      position: 'relative',
      zIndex: 10,
    },

    vertical: {
      '& .MuiTabs-indicator': {
        width: '100%',
      },
    },

    indicator: {
      height: '100%',
      borderRadius: borderRadius.lg,
      backgroundColor: background.default,
      boxShadow: md,
      transition: 'all 500ms ease',
    },
  },
};

export default {
  styleOverrides: {
    fixed: {
      overflow: 'unset !important',
      overflowX: 'unset !important',
    },
    ...tabsMaterialOptions.styleOverrides,
  },
};
