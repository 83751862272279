import { FC } from 'react';
import Collapse from '@mui/material/Collapse';
import ListItem, { ListItemProps } from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Icon from '@mui/material/Icon';
import MdBox from 'src/components/common/Box';
import { item, itemContent, itemArrow } from './styles/sidenavItem';
import { useMaterialUIController } from 'src/assets/theme/context';

type SidenavItemProps = {
  color: 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error' | 'dark';
  name: string;
  active: string | boolean;
  open: boolean;
};

const SidenavItem: FC<Partial<Omit<SidenavItemProps, 'name'>> & { name: string } & ListItemProps> = ({
  name,
  color = 'info',
  active = false,
  children,
  open = false,
  ...rest
}) => {
  const [controller] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode } = controller;

  return (
    <>
      <ListItem
        {...rest}
        component="li"
        sx={(theme) => item(theme, { active, color, transparentSidenav, whiteSidenav, darkMode })}
      >
        <MdBox
          sx={(theme) =>
            itemContent(theme, {
              active,
              miniSidenav,
              name,
              transparentSidenav,
              whiteSidenav,
              darkMode,
            })
          }
        >
          <ListItemText primary={name} />
          {children && (
            <Icon
              component="i"
              sx={(theme) =>
                itemArrow(theme, {
                  open,
                  active: !!active,
                  miniSidenav,
                  transparentSidenav,
                  whiteSidenav,
                  darkMode,
                })
              }
            >
              expand_less
            </Icon>
          )}
        </MdBox>
      </ListItem>
      {children && (
        <Collapse in={open} timeout="auto" unmountOnExit {...rest}>
          {children}
        </Collapse>
      )}
    </>
  );
};

export default SidenavItem;
