import { forwardRef } from 'react';
import MdButtonRoot, { OwnerStateProps } from './MdButtonRoot';
import { useMaterialUIController } from 'src/assets/theme/context';
import { ButtonProps } from '@mui/material/Button';

export type MdTypographyProps = Partial<OwnerStateProps>;

const MDButton = forwardRef(
  (
    {
      color = 'white',
      variant = 'contained',
      size = 'medium',
      circular = false,
      iconOnly = false,
      children,
      ...rest
    }: MdTypographyProps & Omit<ButtonProps, 'color' | 'variant'>,
    ref,
  ) => {
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;

    return (
      <MdButtonRoot
        {...rest}
        ref={ref}
        color="primary"
        variant={variant === 'gradient' ? 'contained' : variant}
        size={size}
        ownerState={{ color, variant, size, circular, iconOnly, darkMode }}
      >
        {children}
      </MdButtonRoot>
    );
  },
);

export default MDButton;
