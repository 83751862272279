import { FC, PropsWithChildren } from 'react';
import List from '@mui/material/List';

const SidenavList: FC<PropsWithChildren<unknown>> = ({ children }) => {
  return (
    <List
      sx={{
        px: 2,
        my: 0.3,
      }}
    >
      {children}
    </List>
  );
};

export default SidenavList;
