import loadable from '@loadable/component';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { Toaster } from 'react-hot-toast';
import { Route, Routes } from 'react-router-dom';
import { useMaterialUIController } from 'src/assets/theme/context';
import darkTheme from 'src/assets/theme/dark';
import lightTheme from 'src/assets/theme/light';
import DashboardNavbar from 'src/components/Navbar';
import DashboardBase from 'src/layouts/DashboardBase';

if (process.env.REACT_APP_APP_MODE !== 'development') {
  // disable console logging
  // eslint-disable-next-line no-console
  console.error = () => {};
}

/*==========================================================================*/
const Home = loadable(() => import('src/pages/Home'));
const PriceIndexRatio = loadable(() => import('src/pages/stocks/PriceIndexRatio'));
const SelfDefinedCategoryMoneyFlow = loadable(() => import('src/pages/stocks/SelfDefinedCategoryMoneyFlow'));
const GenralInfoPage = loadable(() => import('src/pages/stocks/GeneralInfo'));
const GoldPricePage = loadable(() => import('src/pages/goldPrice'));

function App() {
  const [controller] = useMaterialUIController();

  return (
    <ThemeProvider theme={controller.darkMode ? darkTheme : lightTheme}>
      <CssBaseline />
      <Toaster />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="stocks"
          element={
            <DashboardBase>
              <DashboardNavbar />
            </DashboardBase>
          }
        >
          <Route path="price-index-ratio" element={<PriceIndexRatio />} />
          <Route path="self-defined-category-cashflow" element={<SelfDefinedCategoryMoneyFlow />} />
          <Route path="general-info" element={<GenralInfoPage />} />
        </Route>
        <Route
          path="gold-price"
          element={
            <DashboardBase>
              <DashboardNavbar />
            </DashboardBase>
          }
        >
          <Route path="chart" element={<GoldPricePage />} />
        </Route>
      </Routes>
    </ThemeProvider>
  );
}

export default App;
