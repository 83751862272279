import { forwardRef } from 'react';
import InputRootRoot, { OwnerStateProps } from './MdInputRoot';
import { TextFieldProps } from '@mui/material/TextField';

export type MdInputProps = Partial<OwnerStateProps>;

const MdInput = forwardRef(
  ({ success = false, error = false, disabled = false, ...rest }: MdInputProps & TextFieldProps, ref) => (
    <InputRootRoot {...rest} ref={ref} ownerState={{ success, error, disabled }} />
  ),
);

export default MdInput;
